import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VChip } from 'vuetify/lib/components/VChip';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VProgressLinear } from 'vuetify/lib/components/VProgressLinear';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VCard,{staticClass:"mb-5"},[_c(VCardText,{staticClass:"py-0 px-0 px-md-4 py-md-4"},[_c(VDataTable,{ref:"tabela",attrs:{"dense":"","show-expand":"","single-expand":"","item-key":"id","expanded":_vm.expanded,"headers":_vm.headers,"items":_vm.data,"loading":_vm.loading,"no-data-text":_vm.noData,"items-per-page":_vm.perPage,"server-items-length":_vm.totalItems,"page":_vm.page,"footer-props":_vm.$dataTableConfig.getFooterProps(),"loading-text":_vm.$dataTableConfig.getLoadText(),"mobile-breakpoint":0},on:{"click:row":function (item, slot) { return slot.expand(!slot.isExpanded); },"update:expanded":function($event){_vm.expanded=$event},"update:itemsPerPage":function($event){_vm.perPage=$event},"update:items-per-page":[function($event){_vm.perPage=$event},_vm.getData],"update:page":[function($event){_vm.page=$event},_vm.getData]},scopedSlots:_vm._u([{key:"progress",fn:function(){return [_c(VProgressLinear,{attrs:{"absolute":"","indeterminate":"","color":"green"}})]},proxy:true},{key:"item.data_vencimento",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.$date.validDate(item.data_vencimento)))])]}},{key:"item.situacao_titulo",fn:function(ref){
var item = ref.item;
return [_c('span',[_c(VChip,{attrs:{"small":"","color":_vm.getColorStatus(item.situacao_codigo)}},[_vm._v(_vm._s(item.situacao_titulo))])],1)]}},{key:"item.valor",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.$stringFormat.formatNumber(item.valor)))])]}},{key:"item.recebimento",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.recebimento ? item.recebimento.status_nome : '-'))])]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('div',[(item.situacao_codigo === 1)?_c(VIcon,{attrs:{"small":"","disabled":_vm.loading},on:{"click":function($event){return _vm.baixaManual(item)}}},[_vm._v(" mdi-file-download ")]):_vm._e(),(item.recebimento && item.recebimento.status === 5)?_c(VIcon,{attrs:{"title":"transmitir pro sapiens","small":"","disabled":_vm.loading},on:{"click":function($event){return _vm.transmitir(item)}}},[_vm._v(" mdi-send ")]):_vm._e()],1)]}},{key:"no-data",fn:function(){return [_c(VSpacer),_c('div',[_c(VRow,{staticClass:"justify-center mt-1"},[_c(VCol,{staticClass:"col-lg-6"},[_c('p',[_vm._v("Não há dados para serem exibidos")])])],1)],1)]},proxy:true},{key:"expanded-item",fn:function(ref){
var headers = ref.headers;
var item = ref.item;
return [_c('td',{attrs:{"colspan":headers.length}},[_c('ViewComponents',{attrs:{"item":item}})],1)]}}],null,true)})],1),_c('BaixaManualDialog',{attrs:{"item":_vm.itemSelecionado},on:{"input":_vm.getData},model:{value:(_vm.baixaManualDialog),callback:function ($$v) {_vm.baixaManualDialog=$$v},expression:"baixaManualDialog"}}),_c('TransmitirSapiensDialog',{attrs:{"item":_vm.itemSelecionado},on:{"input":_vm.getData},model:{value:(_vm.transmitirDialog),callback:function ($$v) {_vm.transmitirDialog=$$v},expression:"transmitirDialog"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }