<template>
<div>
  <custom-snackbar ref="snackbar" />
  <ActionsComponent
      v-model="headers"
      :filtros="filtros"
      :headers-options="headersOptions"
      relatorio-excel="FaturamentoCobrancas"
      excel-pdf
      local-storage-save-name="faturamento-cobrancas"
      @novo-registro="reloadData = true"
  />
  <FilterComponent v-model="filtros" :loading="loading"/>
  <ListComponent :filtros="filtros" :headers="headers" :reload-data.sync="reloadData" :loading.sync="loading"/>
</div>
</template>

<script>
import { SET_BREADCRUMB } from '@/core/services/store/breadcrumbs.module'

import ActionsComponent from './components/ActionsComponent'
import FilterComponent from './components/FilterComponent'
import ListComponent from './components/ListComponent'

export default {
  components: { ActionsComponent, FilterComponent, ListComponent },
  data: (instance) => ({
    reloadData: false,
    loading: false,
    filtros: {
      dataInicio: instance.filtroValue(instance.$route.query.dataInicio, instance.$date.getDateNowIso(), 'date'),
      dataFim: instance.filtroValue(instance.$route.query.dataFim, instance.$date.getDateNowIso(), 'date'),
      empresaId: instance.filtroValue(instance.$route.query.empresaId, '', 'int'),
      producaoId: instance.filtroValue(instance.$route.query.producaoId, '', 'int'),
      tituloBeneficiario: instance.filtroValue(instance.$route.query.tituloBeneficiario, ''),
      cliente: instance.filtroValue(instance.$route.query.cliente, ''),
    },
    headersOptions: [
      { text: '', value: 'data-table-expand', align: 'center', class: 'custom-table--header', obrigatorio: true },
      { text: 'Status', value: 'situacao_titulo', class: 'custom-table--header', obrigatorio: true },
      { text: 'Empresa', value: 'empresa', class: 'custom-table--header', obrigatorio: true },
      { text: 'Produção', value: 'producao', class: 'custom-table--header', obrigatorio: true },
      { text: 'Cliente', value: 'cliente', class: 'custom-table--header', obrigatorio: true },
      { text: 'Data Vencimento', value: 'data_vencimento', class: 'custom-table--header', obrigatorio: true },
      { text: 'Titulo Beneficiário', value: 'titulo_beneficiario', class: 'custom-table--header', obrigatorio: true },
      { text: 'Titulo Cliente', value: 'titulo_cliente', class: 'custom-table--header', obrigatorio: true },
      { text: 'Valor', value: 'valor', class: 'custom-table--header', obrigatorio: true },
      { text: 'Recebimento', value: 'recebimento', class: 'custom-table--header', obrigatorio: true },
      { text: 'Ações', value: 'actions', align: 'center', class: 'custom-table--header', obrigatorio: true },
    ],
    headers: [],
  }),
  mounted () {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: 'Agricola', route: 'agricola' },
      { title: 'Cobranças' },
    ])
  },
  methods: {
    filtroValue (value, valorPadrao, type) {
      if (value === undefined || value === null || value === '') {
        return valorPadrao
      }

      if (type === 'int') {
        return parseInt(value)
      }

      if (type === 'float') {
        return parseFloat(value)
      }

      if (type === 'array') {
        return [].concat(value)
      }

      return value
    },
  },
}
</script>

<style scoped>

</style>
