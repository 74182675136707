<template>
  <div>
    <v-toolbar flat class="d-flex flex-row-reverse pb-5" color="rgba(0,0,0,0)">
      <v-btn
          color="primary"
          dark
          class="mr-3"
          title="Remover Filtros"
          @click="removeFiltros"
      >
        <v-icon>mdi-filter-remove</v-icon>
      </v-btn>
      <HeaderControl v-model="model" :headers="headersOptions" :local-storage-save-name="localStorageSaveName"></HeaderControl>
      <Exportar v-if="urlPdf.length > 0 || relatorioExcel.length > 0"
                :pdf="urlPdf.length > 0"
                :excel="relatorioExcel.length > 0"
                :filtro="filtros"
                :url-pdf="urlPdf"
                :relatorio-excel="relatorioExcel"
                :excel-pdf="excelPdf"
      ></Exportar>
    </v-toolbar>
  </div>
</template>

<script>
export default {
  props: {
    value: {
      type: Object,
    },
    filtros: {
      type: Object,
    },
    headersOptions: {
      type: Array,
    },
    urlPdf: {
      type: String,
      default: '',
    },
    relatorioExcel: {
      type: String,
      default: '',
    },
    localStorageSaveName: {
      type: String,
    },
    excelPdf: {
      type: Boolean,
      default: false,
    },
  },
  data: () => {
    return {
    }
  },
  computed: {
    model: {
      get () {
        return this.value
      },
      set (val) {
        this.$emit('input', val)
        this.$emit('change', val)
      },
    },
  },
  methods: {
    removeFiltros () {
      this.$router.push({ query: null })
      window.location.reload()
    },
  },
}
</script>

<style scoped>

</style>
