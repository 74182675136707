<template>
  <v-container>
    <custom-snackbar
        ref="snackbar"
    />
    <div v-if="item.recebimento">
      <v-row><v-col offset="2" class="font-weight-bold font-size-base">Baixado por PIX</v-col></v-row>
      <v-row class="my-0">
        <v-col cols="2" class="font-weight-bold text-right py-2">Situração:</v-col>
        <v-col cols="5" class="py-2">{{ item.recebimento.status_nome }}</v-col>
      </v-row>
      <v-row class="my-0">
        <v-col cols="2" class="font-weight-bold text-right py-2">Tx Id:</v-col>
        <v-col cols="5" class="py-2">{{ item.recebimento.txid }}</v-col>
      </v-row>
      <v-row class="my-0">
        <v-col cols="2" class="font-weight-bold text-right  py-2">E2E Id:</v-col>
        <v-col cols="5" class="py-2">{{ item.recebimento.e2eid }}</v-col>
      </v-row>
      <v-row class="my-0">
        <v-col cols="2" class="font-weight-bold text-right  py-2">Valor Pago:</v-col>
        <v-col cols="5" class="py-2">{{ $stringFormat.formatNumber(item.recebimento.valor) }}</v-col>
      </v-row>
      <v-row class="my-0">
        <v-col cols="2" class="font-weight-bold text-right  py-2">Data e Hora Pagamento:</v-col>
        <v-col cols="5" class="py-2">{{ item.recebimento.horario }}</v-col>
      </v-row>
      <v-row class="my-0">
        <v-col cols="2" class="font-weight-bold text-right  py-2">Mensagem do cliente:</v-col>
        <v-col cols="5" class="py-2">{{ item.recebimento.infoPagador || '-' }}</v-col>
      </v-row>
      <v-row class="my-0">
        <v-col cols="2" class="font-weight-bold text-right  py-2">Data de Validação:</v-col>
        <v-col cols="5" class="py-2">{{ item.recebimento.validado_em }}</v-col>
      </v-row>
      <v-row class="my-0">
        <v-col cols="2" class="font-weight-bold text-right  py-2">Data de Transmissão para o Sapiens:</v-col>
        <v-col cols="5" class="py-2">{{ item.recebimento.transmitida_em }}</v-col>
      </v-row>
    </div>
    <div v-else>
      <v-row>
        <v-col v-if="item.situacao_codigo === 1">Esperando baixa</v-col>
        <v-col v-if="item.situacao_codigo === 2">Baixado por remessa ou manual</v-col>
        <v-col v-if="item.situacao_codigo === 3">Cancelado</v-col>
      </v-row>
    </div>
  </v-container>
</template>

<script>

export default {
  props: {
    item: {
      type: Object,
    },
  },
}
</script>
