<template>
  <v-dialog v-model="model" transition="dialog-bottom-transition" max-width="800">
    <custom-snackbar ref="snackbar" />
    <v-card>
      <v-toolbar dark color="info">
        <span class="headline">Transmitir para o sapiens baixa de titulo</span>
      </v-toolbar>

      <v-form ref="form" lazy-validation v-show="showForm">
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="6">
                <EmpresasSelect
                    v-model="form.empresaId"
                    obrigatorio
                    readonly
                />
              </v-col>
              <v-col cols="6">
                <ProducoesSelect
                    v-model="form.producaoId"
                    :empresa="form.empresaId"
                    obrigatorio
                    readonly
                />
              </v-col>
              <v-col cols="12">
                <v-text-field
                    label="Titulo Beneficiário"
                    v-model="form.tituloBeneficiario"
                    disabled
                />
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>

        <v-card-actions>
          <v-spacer />

          <v-btn text color="blue darken-1" @click="close">
            Cancelar
          </v-btn>

          <v-btn text color="blue darken-1" @click="salvar">
            Trasnsmitir
          </v-btn>
        </v-card-actions>
      </v-form>

      <v-card-text v-show="!showForm">
        <div class="text-center p-5">
          <h4 class="mb-4">
            Aguarde...
          </h4>

          <v-progress-circular
              indeterminate
              color="primary"
              :size="100"
              :width="2"
          />
        </div>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import ApiService from '@/core/services/api.service'

export default {
  props: {
    value: {
      type: Boolean,
      required: true,
    },
    item: {
      type: Object,
      required: true,
    },
  },
  data: (instance) => ({
    form: {
      empresaId: null,
      producaoId: null,
      tituloBeneficiario: null,
    },
    showForm: true,
  }),
  computed: {
    model: {
      get () {
        return this.value
      },
      set (val) {
        if (!val) {
          this.reload()
        }

        this.$emit('input', val)
        this.$emit('change', val)
      },
    },
  },
  watch: {
    item: function (val) {
      if (!val) {
        return
      }

      this.form = {
        empresaId: val.empresa_id,
        producaoId: val.producao_id,
        tituloBeneficiario: val.titulo_beneficiario,
      }
    },
  },
  methods: {

    close () {
      this.model = false
    },
    salvar () {
      if (this.$refs.form.validate()) {
        this.showForm = false

        ApiService
          .post('/faturamentos/recebimento/transmitir', { txId: this.item.pix_txid })
          .then((response) => {
            this.$refs.snackbar.show('Cobrança liquidada no sapiens', '', 'success', 5000)
            this.model = false
            this.showForm = true
          })
          .catch((error) => {
            this.$refs.snackbar.show('Problema ao tentar transmitir', error.response.data.message || error.message, 'danger', 5000)
            this.showForm = true
          })
      }
    },
    reload () {
      this.form = {
        empresaId: null,
        producaoId: null,
        dataRealizacao: this.$date.getISOToday(),
        utilizacaoExterna: null,
        observacao: null,
        bemPatrimonial: null,
        produtos: [
          {
            produto: null,
            quantidade: 0,
          },
        ],
      }
    },
  },
}
</script>