<template>
  <v-card class="mb-5">
    <v-card-text class="py-0 px-0 px-md-4 py-md-4">
      <v-data-table
          ref="tabela"
          dense
          show-expand
          single-expand
          item-key="id"
          @click:row="(item, slot) => slot.expand(!slot.isExpanded)"
          :expanded.sync="expanded"
          :headers="headers"
          :items="data"
          :loading="loading"
          :no-data-text="noData"
          :items-per-page.sync="perPage"
          :server-items-length="totalItems"
          :page.sync="page"
          :footer-props="$dataTableConfig.getFooterProps()"
          :loading-text="$dataTableConfig.getLoadText()"
          :mobile-breakpoint="0"
          @update:page="getData"
          @update:items-per-page="getData"
        >
          <template v-slot:progress>
            <v-progress-linear
              absolute
              indeterminate
              color="green"
            />
          </template>

          <template v-slot:[`item.data_vencimento`]="{ item }">
            <span>{{ $date.validDate(item.data_vencimento) }}</span>
          </template>
          <template v-slot:[`item.situacao_titulo`]="{ item }">
            <span><v-chip small :color="getColorStatus(item.situacao_codigo)">{{ item.situacao_titulo }}</v-chip></span>
          </template>

        <template v-slot:[`item.valor`]="{ item }">
          <span>{{ $stringFormat.formatNumber(item.valor) }}</span>
        </template>

        <template v-slot:[`item.recebimento`]="{ item }">
          <span>{{ item.recebimento ? item.recebimento.status_nome : '-' }}</span>
        </template>

          <template v-slot:[`item.actions`]="{ item }">
            <div>
              <v-icon
                  small
                  @click="baixaManual(item)"
                  :disabled="loading"
                  v-if="item.situacao_codigo === 1"
              >
                mdi-file-download
              </v-icon>
              <v-icon
                  title="transmitir pro sapiens"
                  small
                  @click="transmitir(item)"
                  :disabled="loading"
                  v-if="item.recebimento && item.recebimento.status === 5"
              >
                mdi-send
              </v-icon>
            </div>
          </template>

          <template v-slot:no-data>
            <v-spacer />
            <div>
              <v-row class="justify-center mt-1">
                <v-col class="col-lg-6">
                  <p>Não há dados para serem exibidos</p>
                </v-col>
              </v-row>
            </div>
          </template>

          <template v-slot:expanded-item="{ headers, item }">
            <td :colspan="headers.length">
              <ViewComponents :item="item"></ViewComponents>
            </td>
          </template>
        </v-data-table>
    </v-card-text>
    <BaixaManualDialog v-model="baixaManualDialog" :item="itemSelecionado" @input="getData"/>
    <TransmitirSapiensDialog v-model="transmitirDialog" :item="itemSelecionado" @input="getData"/>
  </v-card>
</template>

<script>

import ApiService from '@/core/services/api.service'

import ViewComponents from './ViewComponent'
import BaixaManualDialog from './BaixaManualDialog.vue'
import TransmitirSapiensDialog from './TransmitirSapiensDialog.vue'

export default {
  components: {
    TransmitirSapiensDialog,
    BaixaManualDialog,
    ViewComponents,
  },
  props: {
    headers: {
      type: Object,
    },
    filtros: {
      type: Object,
    },
    reloadData: {
      type: Boolean,
      default: false,
    },
  },
  data: (instance) => ({
    // General
    loading: false,
    baixaManualDialog: false,
    transmitirDialog: false,
    cancelamentoDialog: false,
    noData: 'Nenhuma informação encontrada',
    // Form Data
    data: [],
    // Expand Data Table
    expanded: [],
    itemSelecionado: null,
    perPage: instance.$dataTableConfig.getItemsPerPage(),
    page: 1,
    totalItems: 0,
  }),

  watch: {
    filtros () {
      this.$router.push({ query: this.filtros, silent: true }).catch((e) => e)
      this.getData()
    },
    reloadData (val) {
      if (val) {
        this.getData()
        this.reloadData = false
      }

      this.$emit('update:reloadData', val)
    },
    loading (val) {
      this.$emit('update:loading', val)
    },
  },

  mounted () {
    this.getData()

    this.$refs.tabela.$el.querySelector('thead').addEventListener('wheel', (evt) => {
      evt.preventDefault()
      this.$refs.tabela.$el.querySelector('.v-data-table__wrapper').scrollLeft += evt.deltaY
    })
  },

  methods: {
    getData () {
      this.itemSelecionado = null

      if (!this.filtros.dataInicio || !this.filtros.dataFim) {
        return
      }

      this.loading = true
      this.expanded = []

      this.filtros.per_page = this.perPage
      this.filtros.page = this.page

      ApiService
        .cancel('faturamento-cobrancas')
        .get(
          '/faturamentos/cobrancas',
          '?' + this.$stringFormat.queryParamsRecursive(this.filtros),
        )
        .then((res) => {
          this.data = res.data.data
          this.totalItems = res.data.meta.total

          this.loading = false
        })
        .catch(() => {
          this.loading = false
        })
    },
    baixaManual (item) {
      this.baixaManualDialog = true
      this.itemSelecionado = item
    },
    transmitir (item) {
      this.transmitirDialog = true
      this.itemSelecionado = item
    },
    getColorStatus (codigo) {
      return ({ 1: 'aberto', 2: 'liquidado', 3: 'cancelado' })[codigo]
    },
  },
}
</script>

<style type="text/css">
.v-chip {
  border-radius: 7px!important;
}
.aberto {
  border-color: #FDD01C!important;
  background-color: #FDD01C!important;
  color: #000!important;
}
.aberto--text {
  color: #FDD01C!important;
}
.liquidado {
  border-color: #D1DADE!important;
  background-color: #D1DADE!important;
  color: #000!important;
}
.liquidado--text {
  color: #D1DADE!important;
}
.cancelado {
  border-color: #E36968!important;
  background-color: #E36968!important;
  color: #FFF!important;
}
.cancelado--text {
  color: #E36968!important;
}
</style>
